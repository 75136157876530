// import globalStore from '@/services/global.state';
// import i18n from '@/i18n';
import {Linking} from 'react-native';
import {goCS, goTo} from '@/utils';
import {apiSendPhoneCode, defaultPhoneCode, packageId} from '@/config';

export function toAgentApply() {
  goTo('AgentPage');
  // if (packageId === 5) {
  //   goTo('WebView', {
  //     originUrl: 'https://99lotto.club/',
  //     header: true,
  //     headerTitle: i18n.t('label.agent-rule'),
  //     hideAmount: true,
  //   });
  //   return;
  // }
  // const pathByLangHandler: Record<string, Record<string, string>> = {
  //   singam: {
  //     en_US: '/english-page',
  //     ta_IN: '/tamil-page',
  //     hi_IN: '/hindi-page',
  //     ml_IN: '/malyalam-page',
  //     te_IN: '/telugu',
  //   },
  // };
  // const nameHandler: Record<number, string> = {
  //   // 注释的是还没有的,配置了就解开注释
  //   // 1: 'anna',
  //   2: 'bhau',
  //   3: 'singam',
  //   5: 'dailylotto',
  //   // 6: 'singam',
  // };
  // const name = nameHandler[packageId];
  // if (!name) {
  //   goTo('ProxyRules');
  //   return;
  // }
  // const pathmap = pathByLangHandler[name];
  // const path = pathmap?.[globalStore.lang] ? pathmap[globalStore.lang] : '';
  // goTo('WebView', {
  //   originUrl: `https://agent-${name}.pages.dev${path}?topwindowurl=${
  //     globalStore.isWeb ? location.href : 'android'
  //   }`,
  //   header: true,
  //   headerTitle: i18n.t('label.agent-rule'),
  //   hideAmount: true,
  // });
}

export const goAgentService = () => {
  const linkMap: Record<number, string> = {
    3: 'https://nimble.li/v9a88qqd',
  };
  const whatsAppLink = linkMap[packageId];
  if (whatsAppLink) {
    Linking.openURL(whatsAppLink);
  } else {
    goCS();
  }
};

export function goWhatsAppChat(userPhone?: string, message?: string) {
  if (!userPhone) {
    return;
  }
  Linking.openURL(
    `https://wa.me/${(apiSendPhoneCode ? '' : defaultPhoneCode) + userPhone}${
      message ? `?text=${encodeURIComponent(message)}` : ''
    }`,
  );
}
